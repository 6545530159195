<template>
  <div>
    <div :style="'height:'+height+'px;'"></div>
    <v-footer id="appFooter"
              outlined
              absolute
              inset
              padless>
      <v-container>
        <v-card flat
                tile
                class="greyPrimary flex pa-0">
          <div class="pb-1 d-flex flex-wrap justify-sm-start">
            <!--            <div class="mx-4">-->
            <!--              <a class="text-body-2 black&#45;&#45;text text-decoration-none" href="#">Cómo funciona Airbnb</a>-->
            <!--            </div>-->
            <!--            <v-divider vertical></v-divider>-->
            <div class="mx-3">
              <a class="text-body-2 black--text" target="_blank" href="/terms">Términos</a>
            </div>
            <v-divider vertical></v-divider>
            <div class="mx-3">
              <a class="text-body-2 black--text" target="_blank" href="/policy">Privacidad</a>
            </div>
          </div>

          <div class="d-flex justify-space-between wrap align-center">
            <div class="black--text px-3 text-subtitle-2">
              {{ new Date().getFullYear() }} — <strong>SeOferta</strong>
            </div>
            <div>
              <v-btn v-for="item in links" :key="item.icon" :href="item.url" class="mx-2" color="#00999a" icon link
                     target="_blank">
                <v-icon :color="item.color" size="24px">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
              <v-btn class="mx-1" color="#00999a" href="https://t.me/seoferta" icon link target="_blank">
                <svg aria-hidden="true" height="24" preserveAspectRatio="xMidYMid meet" role="img" viewBox="0 0 24 24"
                     width="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <path
                      d="M20.665 3.717l-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42l10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001l-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15l4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                      fill="#08c"/>
                </svg>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-container>

    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    height: 0,
    links: [
      {
        color: '#3b5998',
        icon: 'mdi-facebook',
        url: 'https://www.facebook.com/seoferta.net',
      },
      {
        color: '#00bb2d',
        icon: 'mdi-whatsapp',
        url: 'https://wa.me/%2B17865058185?text=Hola, necesito ayuda con SeOferta.',
      }
    ],
  }),
  mounted() {
    this.height = document.getElementById('appFooter').clientHeight
    window.addEventListener("resize", this.updatedFakeFooterHeight)
  },
  methods: {
    updatedFakeFooterHeight(e) {
      this.height = document.getElementById('appFooter').clientHeight
    }
  },
}
</script>

<style scoped>

</style>
